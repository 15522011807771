/**
 * @license
 * MyFonts Webfont Build ID 3894062, 2020-04-22T12:38:58-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: NeoSansStd-Light by Monotype
 * URL: https://www.myfonts.com/fonts/mti/neo-sans/light/
 * 
 * Webfont: NeoSansStd-Medium by Monotype
 * URL: https://www.myfonts.com/fonts/mti/neo-sans/medium/
 * 
 * Webfont: NeoSansStd-Regular by Monotype
 * URL: https://www.myfonts.com/fonts/mti/neo-sans/regular/
 * 
 * 
 * Webfonts copyright: Copyright 2004 Monotype Imaging, Inc. All Rights Reserved.
 * 
 * © 2020 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3b6b2e");

@font-face {
  font-family: "NeoSansStd-Medium";
  src: url("assets/fonts/NeoSansStd-Medium/font.woff2") format("woff2"),
    url("assets/fonts/NeoSansStd-Medium/font.woff") format("woff");
}
@font-face {
  font-family: "NeoSansStd-Regular";
  src: url("assets/fonts/NeoSansStd-Regular/font.woff2") format("woff2"),
    url("assets/fonts/NeoSansStd-Regular/font.woff") format("woff");
}
@font-face {
  font-family: "NeoSansStd-Light";
  src: url("assets/fonts/NeoSansStd-Light/font.woff2") format("woff2"),
    url("assets/fonts/NeoSansStd-Light/font.woff") format("woff");
}

html {
  font-family: "NeoSansStd-Regular";
}
