@import url(//hello.myfonts.net/count/3b6b2e);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'NeoSansStd-Regular', sans-serif;
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

*::-webkit-scrollbar {
    width: 8px;
}

/**
 * @license
 * MyFonts Webfont Build ID 3894062, 2020-04-22T12:38:58-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: NeoSansStd-Light by Monotype
 * URL: https://www.myfonts.com/fonts/mti/neo-sans/light/
 * 
 * Webfont: NeoSansStd-Medium by Monotype
 * URL: https://www.myfonts.com/fonts/mti/neo-sans/medium/
 * 
 * Webfont: NeoSansStd-Regular by Monotype
 * URL: https://www.myfonts.com/fonts/mti/neo-sans/regular/
 * 
 * 
 * Webfonts copyright: Copyright 2004 Monotype Imaging, Inc. All Rights Reserved.
 * 
 * © 2020 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */

@font-face {
  font-family: "NeoSansStd-Medium";
  src: url(/static/media/font.53955ca2.woff2) format("woff2"),
    url(/static/media/font.3996455a.woff) format("woff");
}
@font-face {
  font-family: "NeoSansStd-Regular";
  src: url(/static/media/font.199a14d3.woff2) format("woff2"),
    url(/static/media/font.7d875a48.woff) format("woff");
}
@font-face {
  font-family: "NeoSansStd-Light";
  src: url(/static/media/font.1382ff98.woff2) format("woff2"),
    url(/static/media/font.31f2bf78.woff) format("woff");
}

html {
  font-family: "NeoSansStd-Regular";
}

